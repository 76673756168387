import Keycloak from "keycloak-js";
import * as Sentry from "@sentry/browser";

window.fwKeycloak = {
    createKeycloak: function (url, realm, clientId) {
        this.keycloak = new Keycloak({
            url: url,
            realm: realm,
            clientId: clientId
        })
        return Promise.resolve()
    },

    initKeycloak: function (redirectUri) {
        return this.keycloak.init({
            onLoad: 'login-required',
            pkceMethod: 'S256',
            checkLoginIframe: false,
            redirectUri: redirectUri,
            scope: 'offline_access'
        }).catch(this.handleError)
    },

    handlePostLogin: function (loginSessionPath, redirectTo, membershipsDisabledRole, redirectToIfDisabled, authenticated) {
        if (authenticated) {
            console.debug("User authenticated")

            fetch(loginSessionPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    accessToken: this.keycloak.token,
                    refreshToken: this.keycloak.refreshToken
                })
            })
              .then(response => {
                  if (response.ok) {
                      if (this.keycloak.realmAccess.roles.includes(membershipsDisabledRole)) {
                          window.location = redirectToIfDisabled
                      } else {
                          window.location = redirectTo
                      }
                  } else {
                      console.error(`Couldn't save session. Response status ${response.status}`);
                      window.location.reload();
                  }
              })
              .catch(error => {
                  this.handleError(error);
              });
        } else {
            console.debug("User not authenticated")
            return Promise.resolve()
        }
    },

    logout: function (redirectTo) {
        console.debug("User logout")
        document.dispatchEvent(new CustomEvent('wlaDidLogout'))
        this.keycloak.logout({
            redirectUri: redirectTo
        }).catch(this.handleError)
    },

    handleError: function (error) {
        Sentry.captureException(error)
        console.error(error)
    }
}
